@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;500;600;700&display=swap');





  .projects-bg-img {
      background-image: url('https://images.unsplash.com/photo-1488972685288-c3fd157d7c7a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80');
   }

   .project-item-bg-img {
    background-image: url('https://images.unsplash.com/photo-1511818966892-d7d671e672a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80');
 }
  





:root {
  --primary: #3498db;
}


.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

/* styles.css */
.slick-arrow.slick-prev:before,
.slick-arrow.slick-next:before {
  color: #3498db;
  font-size: 20px; /* Increase the font size to make the arrows larger */
  line-height: 1;
}

.slick-dots li button:before {
  color: #3498db; /* Set the desired color for the dots */
}

.card {
  border: 2px solid #3498db;
  background: black;
  border-radius: 8px;
  overflow: hidden;

  color: #fff;
  cursor: pointer;
}

.card-top h1 {
  font-size: 0.9rem;
}

.card-top > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  background: black;
}




/* styles.css or any relevant CSS file */

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 1rem;
  z-index: 1000;
  width: 60%;
  height: 60%;
  border-radius: 0.5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.close-button {
  position: absolute;
  top: -40px;
  right: 0px;
  background: white;
  border: 1px;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}
